import React from "react";

import { Layout, SEO } from '../../components/structure';
import { Button, Content, Hero, PremUpgradeContainer, Testimonial, PointsLoyalty, CallToAction, SplitCabin, LoyaltyCommerce } from "../../components/blocks"
import styled, { css } from "styled-components";
import { brand, font, mq } from "../../styles";
import GatsbyImage from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"



const IndustryCruisePage = (props) => {

  const pageQuery = useStaticQuery(graphql`
    query {
      hero: file(base: { eq: "Plusgrade-Hero-Banner-Cruise.jpg" }) {
       ...FullHeroBackground
      },
      rightArrowIcon: file(base: {eq: "arrow-right-blue.png"}) {
        ...ButtonIcon
      }
      rightArrowIconWhite: file(base: {eq: "arrow-right.png"}) {
        ...ButtonIcon
      }
      cruiseIcon: file(base: {eq: "cruise.png"}) {
        childImageSharp {
          fixed(width: 50, height: 30, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      clickUpgradeIcon: file(base: { eq: "click-upgrade.png" }) {
        childImageSharp {
            fixed(width: 45, height: 80, webpQuality: 100) {
                ...GatsbyImageSharpFixed_withWebp
            }
        }
      }
      clickIncreaseIcon: file(base: { eq: "click-increase.png" }) {
          childImageSharp {
              fixed(width: 45, height: 80, webpQuality: 100) {
                  ...GatsbyImageSharpFixed_withWebp
              }
          }
      }
      clickBidIcon: file(base: { eq: "click-bid.png" }) {
          childImageSharp {
              fixed(width: 27, height: 73, webpQuality: 100) {
                  ...GatsbyImageSharpFixed_withWebp
              }
          }
      }
      dollarIncrease: file(base: { eq: "dollar-increase.png" }) {
          childImageSharp {
              fixed(width: 38, height: 60, webpQuality: 100) {
                  ...GatsbyImageSharpFixed_withWebp
              }
          }
      }
      mobileUpgrade: file(base: { eq: "mobile-upgrade.png" }) {
          childImageSharp {
              fixed(width: 50, height: 60, webpQuality: 100) {
                  ...GatsbyImageSharpFixed_withWebp
              }
          }
      }
      pieChart: file(base: { eq: "pie-chart.png" }) {
          childImageSharp {
              fixed(width: 60, height: 60, webpQuality: 100) {
                  ...GatsbyImageSharpFixed_withWebp
              }
          }
      }
      revPlatformBackground: file(base: { eq: "IndustryCruiseRevenuePlatform.jpg" }) {
        childImageSharp {
          fixed(width: 1100, height: 700, webpQuality: 100){
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      cruiseTestimonials: file(base: { eq: "IndustryCruiseTestimonials.jpg" }) {
        childImageSharp {
          fixed(width: 1100, height: 600, webpQuality: 100){
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      distributeArrow: file(base: { eq: "ArrowDistribute.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, webpQuality: 100){
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      princess: file(base: { eq: "PrincessCruises-Inline.png" }) {
        ...CruiseLogo
      }
      msc: file(base: { eq: "MSCCruises.png" }) {
        ...CruiseLogo
      }
      azamara: file(base: { eq: "AzamaraBlue.png" }) {
        ...CruiseLogo
      }
      virgin: file(base: { eq: "virgin-voyages-inline.png" }) {
        ...CruiseLogo
      }
      uniworld: file(base: { eq: "Uniworld.jpeg" }) {
        ...CruiseLogo
      }
      celeb: file(base: { eq: "CelebrityCruises.png" }) {
        ...CruiseLogo
      }

      norwegian: file(base: { eq: "Norwegian_Cruises_inline.png" }) {
        ...CruiseLogo
      }
      royalcarib: file(base: { eq: "RoyalCaribbean.png" }) {
        ...CruiseLogo
      }

      mobileDevice: file(base: { eq: "Product-Cruise-Premium-Upgrade.png" }) {
        childImageSharp {
            fluid(maxWidth: 260, webpQuality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
    }
    }
  `);

  const PlatformRevenueContainer = styled.div`
  
  ${ font.imports.sherika }
    width: 100%;
    height: auto;
    min-height: 500px;
    margin: auto;
    position: relative;

    .background-con {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      filter: grayscale(1) contrast(1.2);

      &:after {
        content: '';
        position: absolute;
        z-index: 350;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background:linear-gradient(0deg, rgba(0, 4, 20, 0.65), rgba(0, 4, 20, 0.65));
      }

      .gatsby-image-wrapper {
        width: 100% !important;
        height: 100% !important;
      }
    }

    .content-con {
      position: relative;
      z-index: 400;
      width: 95%;
      max-width: 1100px;
      margin: auto;
      height: 100%;
      padding: 75px 0;
      @media only screen and ${mq.maxMd} {
        padding: 30px 0;
      }

      h3 {
        font-size: 38px;
        text-align: center;
        color: ${brand.colors.pg_blue};
        font-weight: 600;
        text-shadow: 0 0 40px ${brand.colors.pg_primary_dark};
      }

      h4 {
        font-size: 24px;
        text-align: center;
        color: #fff;
        font-weight: 600;
      }

      .icon-content-con {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        position: relative;
        top: 130px;
        margin-bottom: 120px;
        @media only screen and ${mq.maxMd} {
          flex-direction: column;
          align-items: center;
          justify-content: center;
          top: 40px;
        }
      }
    }

  `;

  const OutperformContent = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    background-color: ${brand.colors.pg_primary_light};

    @media only screen and ${mq.maxMd} {
      flex-direction: column;
      height: auto;
      padding: 40px 0;
      gap: 40px 0;
    }

    .left-section {
      flex-basis: 50%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .arrow-image-con {
        width: 100%;
        height: 100%;
        @media only screen and ${mq.maxMd} {
          max-width: 500px;
        }
      }
      @media only screen and ${mq.maxMd} {
        width: 100%;
        justify-content: flex-start;
      }
    }

    .right-section {
      height: 450px;
      flex-basis: 50%;
      font-weight: 600;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 90px;
      @media only screen and ${mq.maxMd} {
        padding-left: 0;
        width: 80%;
        margin: auto;
        text-align: center;
        align-items: center;
      }

      .blue-highlight {
        font-size: 80px;
        color: ${brand.colors.pg_blue};
        display: block;
      }

      .copy {
        display: block;
        font-size: 24px;
        color: #000414;
        width: 54%;
        max-width: 350px;
        margin: 15px 0;
      }
    }
  `;

  const PlatformPerksContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    max-width: 1050px;
    margin: auto;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    gap: 40px;
    padding: 60px 0;

    @media only screen and ${mq.maxMd} {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    .headline-highlight{
      display: none;
    }
  `

const CruiseParnters = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 50px auto;

    .cruise-logo-container {
      width: 95%;
      max-width: 1100px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 40px;

      @media only screen and ${mq.maxMd} {
        flex-wrap: wrap;
        justify-content: center;
      }

      .logo-con {
        flex-basis: 200px;
      }
    }
    
`
  return (
    <Layout>
      <SEO title="Cruise | Plusgrade"
            description={'Plusgrade ensures your upper categories always sail full, and at the highest price possible.'}
            keywords={'cruise, cruiseline, upgrades'}
      />
      <Content whiteBG>
        <Hero 
          title={'Cruise'}
          image = {pageQuery['hero']}
          icon = {pageQuery['cruiseIcon']}
          subtitle = {"Ensure your upper categories always sail full, and at the highest price possible."}
          buttonURL = {'/contact/request-a-demo'}
          buttonLabel = {'Book a demo'}
          buttonIcon = {pageQuery['rightArrowIconWhite']}
          imageStyle={{objectPosition: "75% bottom"}}
          fullBG
        />


      <CruiseParnters>
        <div className="cruise-logo-container">
          <div className="logo-con">
            <GatsbyImage fluid={pageQuery['norwegian'].childImageSharp.fluid} imgStyle={{
              objectFit: "none",
              objectPosition: "50% 50%",
            }}/>
          </div>
          <div className="logo-con">
            <GatsbyImage fluid={pageQuery['royalcarib'].childImageSharp.fluid} imgStyle={{
              objectFit: "none",
              objectPosition: "50% 50%",
            }}/>
          </div>
          <div className="logo-con">
            <GatsbyImage fluid={pageQuery['princess'].childImageSharp.fluid} imgStyle={{
              objectFit: "none",
              objectPosition: "50% 50%",
            }}/>
          </div>
          <div className="logo-con">
            <GatsbyImage fluid={pageQuery['celeb'].childImageSharp.fluid} imgStyle={{
              objectFit: "none",
              objectPosition: "50% 50%",
            }}/>
          </div>
          <div className="logo-con">
            <GatsbyImage fluid={pageQuery['msc'].childImageSharp.fluid} imgStyle={{
              objectFit: "none",
              objectPosition: "50% 50%",
            }}/>
          </div>
          <div className="logo-con">
            <GatsbyImage fluid={pageQuery['uniworld'].childImageSharp.fluid} imgStyle={{
              objectFit: "none",
              objectPosition: "50% 50%",
            }}/>
          </div>
        </div>
        <Button destination={'/partners/'} label={'See all partners'} icon={pageQuery['rightArrowIconWhite']} black whiteText pill/>
      </CruiseParnters>

        <div style={{backgroundColor: "#F6F6F6", paddingTop: "1px"}}>
          <PremUpgradeContainer 
            copy = {"Enable passengers to bid for or purchase an upgraded cabin through a white-labeled user experience. As the most widely adopted upgrade solution in the cruise industry, <strong>Premium Upgrade</strong> drives high-margin revenue and delivers incredible customer experiences worldwide."}
            graphicStyle = {{objectPosition: "center bottom", top: "10%"}}
            image={pageQuery['mobileDevice']}

          />
        </div>
        <PlatformRevenueContainer>
          <div className="background-con">
            <GatsbyImage fixed={pageQuery['revPlatformBackground'].childImageSharp.fixed} imgStyle={{objectFit: "cover", objectPosition: "bottom center"}} />
          </div>
          <div className="content-con">
            <h3>Elevate your sailings: build loyalty & drive profitability</h3>
            <h4>After upgrading through the Plusgrade platform, on their next trip:</h4>
            <div className="icon-content-con">
              <IconContainer 
                icon = {pageQuery['clickUpgradeIcon']}
                headline = {'18%'}
                copy = {'booked the class they were upgraded to on their first trip'}
              />

              <IconContainer 
                icon = {pageQuery['clickIncreaseIcon']}
                headline = {'4%'}
                copy = {'booked an even higher class'}
              />

              <IconContainer 
                icon = {pageQuery['clickBidIcon']}
                headline = {'23%'}
                copy = {'placed a higher bid'}
              />
            </div>
          </div>
          
        </PlatformRevenueContainer>
        <OutperformContent>
          <div className="left-section">
            <div className="arrow-image-con">
              <GatsbyImage fluid={pageQuery['distributeArrow'].childImageSharp.fluid} />
            </div>
          </div>
          <div className="right-section">
            <span className="blue-highlight">2x</span>
            <span className="copy">Plusgrade-powered cruise upgrade programs at least double within the first year.</span>
          </div>
        </OutperformContent>
        <PlatformPerksContainer>
          <IconContainer whiteOrange
                icon = {pageQuery['dollarIncrease']}
                headline = {'Optimized ship revenue'}
                copy = {'Our solution analyzes available inventory and your pool of pledged upgrades to recommend the optimal selection of passengers to upgrade.'}
            />

            <IconContainer whiteOrange
                icon = {pageQuery['mobileUpgrade']}
                headline = {'Encourage future buy-ups'}
                copy = {'Incentivize guests to try a higher cabin at a price they are willing to pay, and then get double the benefit when they rebook at full fare.'}
            />

            <IconContainer whiteOrange
                icon = {pageQuery['pieChart']}
                headline = {'Control inventory & leverage granular pricing rules'}
                copy = {'Your inventory won\’t change, but how customers see it will, increasing value for them and for your brands.'}
            />
          
        </PlatformPerksContainer>
        <Testimonial 
          testimonials={
            [
              "We were running a very manual upgrade process. The biggest differentiator, when introducing MoveUp [the upgrade platform], is we realized that guests are willing to pay way more than we would ever have asked for. So, higher bids, huge guest excitement, and strong ability to reach our guests directly and through our trade community. In just 3 months, the revenue is 10x what we would have done in our previous program.--Melissa Beckwith, Director, Revenue Management, Celebrity Cruises",
              "Before Plusgrade, we had a team of 6 fulltime coordinators with sales incentives to email/call guests for upgrades. Now, we only have 3 full time salaried coordinators who fulfill the upgrades in our res system. Plus, they have the capacity to handle other responsibilities, like inventory control for close-in sailings. The other 3 employees were reallocated within the organization.--Valeria Nicolaci, Director Revenue Development & Ops Effectiveness, Norwegian Cruise Lines"
            ]
          }
          signature = {'Paul vR'}
          background={pageQuery['cruiseTestimonials']}
          imageStyle = {{objectPosition: "top center"}}
        />
        <LoyaltyCommerce />
        <PointsLoyalty 
          headline={'Loyalty Commerce Platform'}
          spaceBetween/>

        <CallToAction
          title={'Drive ancillary revenue\nwith incredible customer experiences'}
          subtitle={'Contact us today to learn more.'}
          > 
          <Button destination={'/contact/request-a-demo'} label={'Request a demo'} icon={pageQuery['rightArrowIcon']} white blueText pill />
        </CallToAction>

      </Content>
    </Layout>
  );
}

export default IndustryCruisePage;

const IconCon = styled.div`

${ font.imports.sherika }
  flex-basis: 24%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  .icon-con {
    @media only screen and ${mq.maxMd} {
      margin: auto;
    }
  }

  .headline {
    font-family: ${font.family.sherika};
    font-weight: 600 !important;
    color: ${brand.colors.pg_blue} !important;
    font-size: 34px !important;
    margin-bottom: 5px;
    @media only screen and ${mq.maxMd} {
      text-align: center;
    }
  }

  .headline-highlight {
    color: ${brand.colors.pg_primary_light};
    margin-top: 30px;
    @media only screen and ${mq.maxMd} {
      text-align: center;
    }
  }

  p{
    margin-top: 10px;
    font-family: ${font.family.sherika};
    font-weight: 700;
    font-size: 16px;
    color: ${brand.colors.pg_primary_light};
    @media only screen and ${mq.maxMd} {
      text-align: center;
    }
  }

  ${props => props.whiteOrange && css`
    flex-basis: 30%;
    align-items: flex-start;
    flex-wrap: wrap;

    .headline-con {
      text-align: left;
      @media only screen and ${mq.maxMd} {
        text-align: center;
        align-items: center;
      }
      h4 {
        color: ${brand.colors.pg_primary_dark} !important;
        font-family: ${font.family.sherika};
        font-size: 24px !important;
        font-weight: 700;
        white-space: pre-line;
      }
      p {
        color: ${brand.colors.pg_primary_dark};
        font-family: ${font.family.sherika};
        font-weight: 400;
        margin: 20px 0;
      }

      .headline{
        min-height: 60px;
        display: flex;
        align-items: center;
        @media only screen and ${mq.maxMd} {
          justify-content: center;
        }
      }
    }
  `}
`;

const IconContainer = ({icon, highlight, headline, copy, ...props }) => {
  const ic = ( icon ? <GatsbyImage fixed={icon.childImageSharp.fixed}/> : <></>);
  return(
    <IconCon {...props}>
      <div className="icon-con">
        {ic}
      </div>
      <div className="headline-con">
      <h4 className="headline-highlight">{highlight}</h4>
        <h4 className="headline">
          {headline}
        </h4>
        <p>
          {copy}
        </p>
      </div>

    </IconCon>
  )
}

export const query = graphql`
  fragment CruiseLogo on File {
    childImageSharp {
      fluid(maxWidth: 125, webpQuality: 100) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;

